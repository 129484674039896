<template>

<app-content :loading="is.loading">

	<app-content-body :is-grid="2">

		<com-next :next="data.next" />
		<app-widget-activity id="all" />
		<app-widget-events id="all" v-if="$isBadgeActive" />

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-next': () => import('./conventiondashboard/Next')
	},

	data: function() {

		return {
			is: {
				loading: true
			},
			data: {
				next: false
			}
		}

	},

	created: function() {

		this.load()

	},

	methods: {

		load: function() {

			this.$api.get('convention/dashboard').then(function(json) {

				Vue.set(this, 'data', json)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.dashboard-row {
	display: grid;
	grid-template-columns: auto 400px;
	grid-gap: 20px;
	grid-template-rows: 400px;
}

.is-mobile .dashboard-row {
	grid-template-columns: auto;
	grid-template-rows: 400px;
}

.is-mobile .dashboard-row >>> .box-content {
	min-height: 300px;
}

</style>